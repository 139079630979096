.seg-container{
  --highlight-width: auto;
  --highlight-x-pos: 0;
  padding: 0.25vh;
  display: flex;
  justify-content: space-between;
  /* margin-right: 1vh;
  margin-left: 1.6vh; */
  font-size: 1.6vh;
  align-items: center;
 
}

.segmented-control {
  display: inline-flex;
  justify-content: center;
  background: #e2e2e7;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
  border-radius: 1vh;
  width: 70vh;
  margin: auto;
  /* padding: 0.25vh; */
  /* margin: auto; */
  overflow: hidden;
  position: relative;
  border: 1px solid #e2e2e7;
  }
  
  .segmented-control-button {
    flex: 1;
    border: none;
    cursor: pointer;
    height: 3.2vh;
    font-size: 1.7vh;
    background-color: #e2e2e7;
    color: black;
    padding-top: 0.5vh;
    
  }
  
  .segmented-control-button.selected {
    background-color: #007bff;
    color: #fff;
    border: 1px solid  #007bff;
  }

  .segmented-control-button.unselected {
    background-color: #e2e2e7;
    color: black;
    border: 1px solid  #e2e2e7;
  }
  
  .first-button {
    border-top-left-radius: 0.5vh;
    border-bottom-left-radius: 0.5vh;
    border-right: 0.5px solid white;
  }
  
  .last-button {
    border-top-right-radius: 0.5vh;
    border-bottom-right-radius: 0.5vh;
    border-left: 0.5px solid white;
  }
  .middle-button {
    border-left: 0.5px solid white;
    border-right: 0.5px solid white;

  }

  /* .segmented-control-button::before {
    content: "";
    background: white;
    border-radius: 1vh;
    width: var(--highlight-width);
    transform: translateX(var(--highlight-x-pos));
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  } */

  .segmented-control-button.ready::before {
    transition: transform 0.3s ease, width 0.3s ease;
  }
  
  