.menu_Logo{
    height: 8%;
}
.logo_Costumer{
    height: 100%;
}
.menu_Head{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 2vh;
    font-weight: bold;
    text-align: left;
    margin-left: 1vw;
    margin-right: 1vw;
    padding-bottom: 0px;
    margin-bottom: -0.5vw;
}
.icon_Head{
    font-size: 1vw;
    color: rgb(0, 122, 254);
    margin-top: 0.5vh;
}
.unsel_Item{
    font-size: 1.8vh;
    color: black;
    text-align: left;
    background-color: #F2F2F7;
    margin-left: 1.8vh;
    margin-right: 0.3vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-left: 2vh;
}
.sel_Item{
    font-size: 1.8vh;
    width: 26.2vh;
    color: white;
    text-align: left;
    background-color: rgb(0, 122, 254);
    margin-left: 1.8vh;
    margin-right: 0.3vh;
    padding-left: 2vh;
    padding-top: 1vh;
    padding-right: 1vh;
    padding-bottom: 1vh;
    border-radius: 0.6vh;
}
.p_Item{
    margin: 0.3vw;
    
}
.show_menuBme{
    display: none;
}

nav a {
    text-decoration: none;
    color: black;
}

nav a:hover{
    font-weight: bold;
}
