.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo escuro semi-transparente */
    display: flex;
    justify-content: center; /* Centraliza o conteúdo horizontalmente */
    align-items: center; /* Centraliza o conteúdo verticalmente */
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
  }
  
  .progress-bar-container {
    width: 100%;
    background-color: #f0f0f0;
    border-radius: 4px;
  }
  
  .progress-bar {
    width: 50%; /* Ajuste conforme necessário */
    height: 10px;
    background-color: #007bff; /* Cor da barra de progresso */
    border-radius: 4px;
  }
  .modal-text{
    font-size: 2vh;
    padding-left: 5vh;
    padding-right: 5vh;
    padding-bottom: 2vh;
  }
  